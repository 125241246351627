<template>
  <div class="product-list__item">
    <div class="product-list__item__description">
      <div class="product-list__item__description__icon">
        <font-awesome-icon icon="file-contract" class="product-list__item__description__icon" />
      </div>
      <div class="product-list__item__description__content">
        {{boughtItemDate}}
        <br />
        <span>{{itemDesc}}</span>
      </div>
    </div>
    <div class="product-list__item__action ms-auto">
      <a
        v-if="hasBoughtItem"
        class="btn btn-info btn-sm"
        target="_blank"
        :href="boughtItemLink"
      >
        <font-awesome-icon
          icon="download"
          class="fa-fw me-2 me-md-0 me-xl-2"
        />
        <span class="d-md-none d-xl-inline-block">Scarica</span>
      </a>
      <b-button
        v-else
        variant="success"
        size="sm"
        @click="buyAction(item)"
      >
        <font-awesome-icon
          icon="cart-shopping"
          class="fa-fw me-2 me-md-0 me-xl-2"
        />
        <span class="d-md-none d-xl-inline-block">Acquista</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import { isNotEmpty } from '../../utils/validators';
import { formatDateOnly } from '../../utils/formatterHelper';

export default {
  name: 'ProductTableItemWizard',
  components: {},
  props: {
    item: {
      codAtto: String,
      descCodAtto: String,
      dtChiusura: String,
      requestName: String,
    },
    buyAction: Function,
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    hasBoughtItem() {
      return isNotEmpty(this.item.requestName);
    },
    boughtItemDate() {
      return formatDateOnly(this.item.dtChiusura);
    },
    boughtItemLink() {
      if (this.hasBoughtItem) {
        return `/#/user-analysis-detail/${this.item.requestName}`;
      }
      return '#';
    },
    itemDesc() {
      return this.item.descCodAtto;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
